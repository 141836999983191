/* .custom-cursor {
  position: fixed;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid var(--color--secondary);
  pointer-events: none;
  z-index: 9999;
  transition: all 0.3s ease-out;
} */

@media screen and (max-width: 1200px) {
  [class*='neon-spinner'] {
    display: none;
  }
}

.neon-spinner {
  position: relative;
  width: 100px;
  height: 100px;
  z-index: -1;
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
}

.circle-1 {
  border-top-color: #fff;
  border-right-color: #fff;
  animation: rotate-clockwise 2s linear infinite;
}

.circle-2 {
  border-bottom-color: #fff;
  border-left-color: #fff;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  animation: rotate-counterclockwise 2s linear infinite;
}

@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg);
    box-shadow: 0 0 10px #fff;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 0 0 30px #fff, 0 0 50px #fff;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 0 0 10px #fff;
  }
}

@keyframes rotate-counterclockwise {
  0% {
    transform: rotate(0deg);
    box-shadow: 0 0 10px #fff;
  }
  50% {
    transform: rotate(-180deg);
    box-shadow: 0 0 30px #fff, 0 0 50px #fff;
  }
  100% {
    transform: rotate(-360deg);
    box-shadow: 0 0 10px #fff;
  }
}
