@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap');
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
}

:root {
  --color--primary: #190526;
  --color--secondary: #4f1a73;
  --color--secondary--strong: #3d1259;
  --color--secondary--strongest: #2a0940;
  --gray--text: #baaebf;
  --container--width--lg: 75%;
  --container--width--md: 85%;
  --container-width-ms: 90%;
  --font--shadow: 'Shadows Into Light Two', cursive;
  --transition: all 500ms ease;

  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-lg: 20px;
  --font-size-xl: 24px;
  --font-size-2xl: 28px;
  --font-size-3xl: 32px;
}

body {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  /* font-family: 'Shadows Into Light Two', cursive; */
  font-size: 14px;
  line-height: 1.8;
  background-color: var(--color--primary);
  letter-spacing: 2px;
}

.container {
  margin: 0 auto;
  width: var(--container--width--lg);
}

section {
  margin-top: 8rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

html {
  scroll-behavior: smooth;
}

a {
  color: white;
  transition: var(--transition);
}

a:hover {
  color: var(--color--secondary);
}

.btn {
  width: max-content;
  display: inline-block;
  color: white;
  background-color: var(--color--secondary);
  padding: 0.8rem 1.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: var(--transition);
  font-weight: 500;
}

.btn-secondary {
  width: max-content;
  display: inline-block;
  color: var(--color--secondary);
  background-color: white;
  padding: 0.8rem 1.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 3px solid var(--color--secondary);
  transition: var(--transition);
  font-weight: 600;
}

.btn-secondary:hover {
  background-color: #e2d6e8;
}

.btn:hover {
  background-color: #2a0940;
  color: white;
  border-color: transparent;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* Media queries for tablets */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  .section {
    margin-top: 6rem;
  }
}

/* Media queries for mobile phones */
@media screen and (max-width: 800px) {
  .container {
    width: var(--container-width-sm);
  }
  .section {
    margin-top: 5rem;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
  padding-right: 20px;
  padding-left: 20px;
}

@media only screen and (max-width: 800px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.5rem !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.swiper-pagination-bullet {
  background-color: var(--gray--text);
  width: 10px;
  height: 10px;
  margin: 0 5px;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
}

.swiper-pagination-bullet:hover {
  opacity: 0.8;
}
