.swipe-animation {
  position: relative;
  top: 25%;
  left: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100000;
  gap: 5px;
}

.hand-icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 70px;
  animation: swipe-hand-left 1.5s infinite;
}

.path {
  width: 20px;
  height: 20px;
  background: rgba(149, 147, 241, 0.5);
  border-radius: 40px;
  animation: swipe-dot-left 1.5s infinite;
}

@keyframes swipe-hand-left {
  25% {
    transform: translate(-75px, 0) rotate(-15deg);
  }

  100% {
    transform: translate(0, 0) rotate(30deg);
  }
}

@keyframes swipe-dot-left {
  12% {
    visibility: visible;
    width: 45px;
  }
  25% {
    visibility: visible;
    transform: translateX(-85px);
    width: 20px;
  }
  26% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes swipe-dot-up {
  12% {
    visibility: visible;
    height: 45px;
  }
  25% {
    visibility: visible;
    transform: translateY(-85px);
    height: 20px;
  }
  26% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes swipe-hand-up {
  25% {
    transform: translate(0, -100px) rotate(0);
  }

  100% {
    transform: translate(0, 0px) rotate(-15deg);
  }
}
