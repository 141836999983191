.swiper {
  width: 70%;
  height: 80%;
}

.swiper-slide {
  text-align: center;
  font-size: var(--font-size-lg);
  color: white;
  display: flex;

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.swiper-slide-active {
  animation: scale 3s ease forwards;
}
